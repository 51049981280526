<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><router-link to="/info">个人中心</router-link>><a
            href="javascript:;">合同管理</a>
        </div>
      </div>
      <div class="personal per_invoice pt30 m1440 flex-box flex-col-start">
        <div class="left_nav fs18">
          <router-link to="/info">基本信息</router-link>
          <router-link to="/certificate">证书抬头</router-link>
          <router-link to="/maintain_list">开票信息</router-link>
          <router-link to="/address_list">收件地址</router-link>
          <router-link to="/invoices_status">发票管理</router-link>
          <router-link to="/contract_management" class="active">合同管理</router-link>
          <router-link to="/change_password">修改密码</router-link>
          <router-link to="/change_phone">修改手机号</router-link>
          <a @click="cancellation()" class="cursor" href="javascript:;">删除账户</a>
        </div>
        <div class="right_box flex-grow-1">
          <div class="flex-box invoice_nav fs18">
            <div class="flex-box flex-grow-1">
              <div class="nav" @click="changeContract(0)" :class="conIndex == 0 ? 'active' : ''">待生成</div>
              <div class="nav" @click="changeContract(1)" :class="conIndex == 1 ? 'active' : ''">已生成</div>
            </div>
          </div>
          <div v-if="cert_list.length == 0 && isShowNavBarLoading == true">
            <div class="no_data">
              <img src="../assets/static/icon/z_nothing.png" class="z_nothing" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div v-else>
            <ul class="order_list">
              <li class="mb30" v-for="(item, index) in cert_list" :key="index">
                <div class="flex-box num_date">
                  <div class="col9">订单号：</div>
                  <div>{{ item.tradeNo }}</div>
                  <div class="pl60 col9">{{ item.createTime }}</div>
                </div>
                <div class="flex-box">
                  <div class="goods_info flex-grow-1 flex-box" v-for="(v, index) in item.product">
                    <img :src="oss + v.thumbnail" class="img_about mr20" alt="" />
                    <div class="title">
                      <div class="fs20 fwb">{{ v.productName }}</div>
                      <div class="fs20 num">x{{ item.totalCount_text }}</div>
                    </div>
                  </div>
                  <div class="fifteen fs28 red fwb"><span class="fs16">¥</span>{{ item.price_text }}</div>
                  <div class="fifteen fs20">
                    <div class="pb15 col9">已完成</div>
                    <a href="javascript:;" @click.stop="jump_detail(item.id)">订单详情</a>
                  </div>
                  <div class="fifteen">
                    <div class="btns" v-if="item.contractStatus == 0" @click.stop="createShow(index)">生成合同</div>
                    <div class="btns" v-if="item.contractStatus == 1" @click.stop="jump(1, index)">预览合同</div>
                    <div class="btns" v-if="item.contractStatus == 1" @click.stop="jump(2, index)">下载合同</div>
                  </div>
                </div>
              </li>
            </ul>
            <!-- <div class="nomore" v-if="isShowNavBarLoading">没有更多了~</div> -->
            <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
            <Pagination :sumCount="total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination>
          </div>
        </div>
        <Popup :visible="popup" :confirmText="false" :cancleText="false" @close="closePop">
          <div class="sure_pop">
            <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="closePop()" alt="" />
            <img src="../assets/static/icon/icon_contract.png" class="icon_contract" alt="" />
            <div class="fs16 tc pb40">是否确定生成合同？</div>
            <div class="sure_cancel flex-box flex-center fs18">
              <div @click="closePop">取消</div>
              <div v-if="is_first == false" @click="create">确定</div>
              <div v-else>生成中</div>
            </div>
          </div>
        </Popup>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
import cookie from "vue-cookies";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      popup: false,
      conIndex: 0,
      oss: this.global.uploadOss,
      cert_list: [],
      pageSize: 10, //分页大小
      total: 0, //分页总数
      pageNum: 1, // 分页记录数
      isShowNavBarLoading: false, // 是否加载完
      s_index: -1,
      userinfo: {},
      is_first: false
    };
  },
  // 页面渲染完执行
  created() {
    let _that = this;
    _that.get_list();
  },

  methods: {
    closePop() {
      this.s_index = -1;
      this.popup = !this.popup;
      this.is_first = false
    },

    createShow(index) {
      this.s_index = index;
      this.popup = !this.popup;
    },

    create() {
      let list = this.cert_list
      let index = this.s_index
      if (index < 0) {
        this.$message.error('请选择要生成的合同订单');
        return;
      }
      //判断用户是否完善了公司信息
      let userinfo = cookie.get("huanbaoqiao_userinfo");
      if (!userinfo.companyName || !userinfo.companyNameEn || !userinfo.businessLicense
        || !userinfo.companyAddress || !userinfo.contactor || !userinfo.contactorTel || !userinfo.contactorEmail) {
        this.$message.error('请先完善公司信息');
        setTimeout(function () {
          this.$router.push("/info")
        }, 800)
        return;
      }
      if (list[index].contractPdf != null && list[index].contractPdf != '') {
        this.$message.error('当前订单已生成合同');
        return;
      }
      this.is_first = true
      this.$api.createDraftContract({ tradeId: list[index].id }).then((res) => {
        // loading.close();
        if (res.resCode == 0) {
          this.$message.success(res.resMsg);
          // list[index].contractStatus = 1
          list.splice(index, 1)
          this.cert_list = list
          this.s_index = 0;
          this.popup = false;
          this.is_first = false
        } else {
          this.$message.error(res.resMsg);
          this.s_index = -1;
          this.popup = false;
          this.is_first = false
        }
      }).catch((err) => {
        this.is_first = false
				});;
    },

    jump(type, index) {
      let list = this.cert_list
      let tradeId = list[index].id
      if (type == 1) {
        //  预览
        this.$api.browseContractPage({ tradeId: tradeId }).then((res) => {
          if (res.resCode == 0) {
            window.open(res.root.pageUrl, "_blank");
          } else {
            this.$message.error(res.resMsg);
          }
        });
      } else {
        // 下载
        this.$api.downloadContractPDF({ tradeId: tradeId }).then((res) => {
          if (res.resCode == 0) {
            var fileName = res.root.root.contractPdf
            var url = this.oss + fileName
            window.open(url, "_blank");
          } else {
            this.$message.error(res.resMsg);
          }
        });
      }
    },

    jump_detail(id) {
      this.$router.push("/order_detail?code=" + escape('环保桥' + id));
    },

    // 分页
    pageTurn(page) {
      this.pageNum = page + 1;
      this.get_list();
    },
    // 获取抬头
    get_list(s_param = {}) {
      var that = this;
      let page = that.pageNum;
      s_param.page = page;
      s_param.deleted = '0';
      s_param.limit = that.pageSize;
      s_param.pkg_product_father = 'com.dazz.business.model.product.Product',
        s_param.status = 'COMPLETE'
      let navIndex = that.conIndex
      if (navIndex == 0) {
        s_param.contractStatus = '0'
      } else if (navIndex == 1) {
        s_param.contractStatus = '1'
      } else if (navIndex == 2) {
        s_param.contractStatus = '2'
      }
      this.$api.getInvoices(s_param).then((data_res) => {
        //console.log(data_res);
        if (data_res.resCode == 0) {
          if (JSON.stringify(data_res.root) === "{}" || data_res.root.length <= 0) {
            that.isShowNavBarLoading = true;
          } else {
            // 判断 是否 加载完毕
            var is_data = true;
            var totalCount = data_res.totalCount;
            var show_total = that.pageNum * that.pageSize;
            if (totalCount > show_total) {
              //当只有一页的时候展示
              is_data = true;
            } else {
              is_data = false;
            }
            that.total = totalCount;
            let list = data_res.root
            for (var i = 0; i < list.length; i++) {
              list[i].price_text = that.global.addCommas(list[i].product[0].price)
              list[i].totalCount_text = that.global.addCommas(list[i].totalCount)
            }
            that.cert_list = list;
            that.isShowNavBarLoading = is_data;
          }
        } else {
          that.isShowNavBarLoading = true;
          that.$message.error(data_res.msg);
        }
      });
    },

    changeContract(index) {
      let that = this
      that.conIndex = index;
      that.pageSize = 10, //分页大小
        that.total = 0, //分页总数
        that.pageNum = 1, // 分页记录数
        that.cert_list = []
      that.get_list();
    },

    //删除
    cancellation() {
      let that = this;
      MessageBox.confirm("是否确定删除当前账户?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            let userinfo = cookie.get("huanbaoqiao_userinfo");
            that.$api.logOffAccount({ userId: userinfo.id }).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                cookie.remove("huanbaoqiao_token");
                cookie.remove("huanbaoqiao_userinfo");
                setTimeout(function () {
                  that.$router.push("/");
                }, 800)
              } else {
                that.$message.error(res.resMsg);
              }
            });

          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    },

  },
};
</script>
